  .captcha_text {
    color: #00C000;
    font-size: 24px;
    // font-weight: 700;
  }

  img,
  object,
  embed,
  video {
    max-width: 100%;
  }

  .ie6 img {
    width: 100%;
  }

  /* Mobile Layout: 480px and below. */
  .top-bar {
    float: left;
    width: 100%;
    height: 2px;
    background: #737373;
    border-bottom: 2px solid #249fab;
    position: fixed;
    z-index: 3;
  }

  .gridContainer {
    margin-left: auto;
    margin-right: auto;
    width: 87.36%;
    padding-left: 1.82%;
    padding-right: 1.82%;
  }

  #LayoutDiv1 {
    clear: both;
    float: left;
    margin-left: 0;
    width: 100%;
    display: block;
  }

  .header {
    float: left;
    width: 100%;
    height: auto;
    margin: 20px 0 10px 0;
    padding: 0;
    position: relative;
    display: block;
  }

  .login-button {
    float: right;
    width: auto;
    height: auto;
    margin: 34px 50px 0 0;
    padding: 0;
    position: relative;
    display: block;
    font-size: 36px;
  }

  .logo {
    float: left;
    width: 300px;
    height: auto;
    margin: 20px 0 0 0;
    padding: 0;
    position: relative;
    display: block;
  }

  .panel {
    display: none;
    float: left;
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 3;
    background: rgba(0, 0, 0, 0.4);
    padding: 10px;
  }

  .panel a {
    float: right;
  }

  .user-details {
    position: absolute;
    right: 0;
    text-align: right;
    margin: 0 0 10px 0;
    background: #fff;
    padding: 0 10px;
    color: #20b7c9;
    border-radius: 5px;
    border: 2px solid #d1d1d1;
    box-shadow: 0px 0px 14px #fff;
    height: 35px;
    line-height: 35px;
    display: flex;
    justify-content: center;
    min-width: 165px;
  }

  .user-details i {
    margin: 10px 0 0 0;
  }

  .responsive_menu select {
    display: block;
    width: 100%;
    height: 36px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857;
    color: #ffffff;
    vertical-align: middle;
    background-color: #20b7c9;
    background-image: none;
    border: none;
  }

  nav {
    float: left;
    width: 100%;
    height: auto;
    margin: 20px 0 0 0;
    padding: 0;
    position: relative;
    display: block;
  }

  nav ul {
    float: left;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }

  nav ul li {
    margin: 0;
    display: inline-block;
    list-style: none;
  }

  nav ul li a {
    text-decoration: none;
    padding: 10px 18px;
    float: left;
  }

  nav ul li a:hover {
    background: #20b7c9;
  }

  .active {
    background: #20b7c9;
  }

  .container {
    float: left;
    width: 96%;
    height: auto;
    margin: 30px 0 0 0;
    padding: 20px 2%;
    position: relative;
    display: block;
    min-height: 300px;
    z-index: 1;
    border-radius: 0 50px 0 0;
    border: 1px solid #fff;
    border-bottom: none;
    background: rgba(255, 255, 255, 0.6);
  }

  .login-div {
    float: left;
    width: 96%;
    height: auto;
    margin: 50px 2% 0 2%;
    padding: 0 0 10px 0;
    position: relative;
    display: block;
    border: 1px solid #ffffff;
    box-shadow: 0px 0px 5px #999999;
    z-index: 2;
    background: rgba(0, 0, 0, 0.3);

    @media(max-width:768px) {
      margin-top: 20px;
    }
  }

  .fields {
    float: left;
    width: 92%;
    margin: 10px 4%;
    padding: 0;
    position: relative;
    display: block;
    background: rgb(226, 226, 226);
    /* Old browsers */
    /* IE9 SVG, needs conditional override of 'filter' to 'none' */
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2UyZTJlMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
    background: -moz-linear-gradient(top,
        rgba(226, 226, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%);
    /* FF3.6+ */
    background: -webkit-gradient(linear,
        left top,
        left bottom,
        color-stop(0%, rgba(226, 226, 226, 1)),
        color-stop(100%, rgba(255, 255, 255, 1)));
    /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,
        rgba(226, 226, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%);
    /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,
        rgba(226, 226, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%);
    /* Opera 11.10+ */
    background: -ms-linear-gradient(top,
        rgba(226, 226, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%);
    /* IE10+ */
    background: linear-gradient(to bottom,
        rgba(226, 226, 226, 1) 0%,
        rgba(255, 255, 255, 1) 100%);
    /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#e2e2e2', endColorstr='#ffffff', GradientType=0);
    /* IE6-8 */
  }

  .login-row-width {
    width: 100%;
  }

  .login-submit {
    width: 92%;
    margin: 0 4%;

    @media(max-width:768px) {
      display: flex !important;
      justify-content: space-between !important;

      .login-fields {
        width: max-content;
      }

      .formloginBtn {
        margin-left: 10px;
      }
    }
  }

  .login-row {
    float: left;
    height: 40px;
    padding: 0;
    position: relative;
    display: block;
    line-height: 40px;
  }

  .login-row h2 {
    margin: 0;
    background: #4fd3dd;
    padding: 0;
    color: #fff;
    text-align: center;
    width: 100%;
    float: left;
  }

  .login-label {
    float: left;
    width: 12%;
    height: auto;
    margin: 2px 0 0 5%;
    padding: 0;
    position: relative;
    display: block;
  }

  .login-fields {
    float: left;
    width: 68%;
    height: auto;
    margin: -3px 2% 0 0;
    padding: 0;
    // position: calc();
    display: block;

    input {
      outline: none;
      background: transparent !important;
    }
  }

  .login-row span {
    float: right;
    position: absolute;
    top: 5px;
    right: 40px;
    margin-top: -6px;
    color: #047e37;
    letter-spacing: 1px;
    font-weight: 500;
    font-size: 22px;
  }

  .imgLeftMargin {
    margin-left: -10px;
  }

  .login-fields input[type="submit"] {
    float: right;
  }

  .login-fields input[type="text"],
  input[type="password"] {
    width: 180px;
    height: 35px;
    border-radius: none;
    border: none;
  }

  .bar-chart {
    position: absolute;
    display: block;
    right: 20px;
    bottom: 20px;
    width: 120px;
    z-index: 1;
    opacity: 0.2;
  }

  footer {
    float: left;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
    line-height: 40px;
    color: #fff;
    // background: url(../images/footer-shadow.png) bottom repeat-x #16b2c3;
    border-radius: 0 0 20px 20px;
    margin: 0 0 20px 0;
    border: 1px solid #ffffff;
    border-top: none;
  }

  .copyright {
    float: left;
    width: 100%;
    height: auto;
    margin: 0;
    padding: 0 2%;
    position: relative;
    display: block;
  }

  /*-> inner page css*/
  .row {
    width: 100%;
    float: left;
    width: 100%;
    margin: 0 0 10px 0;
    padding: 0;
    position: relative;
    display: block;
    z-index: 2;
  }

  .row-left {
    float: left;
    width: 100%;
    height: auto;
    margin: 0 0 10px 0;
    padding: 0;
    position: relative;
    display: block;
  }

  .row-mid {
    float: left;
    width: 100%;
    height: auto;
    margin: 0 0 10px 0;
    padding: 0;
    position: relative;
    display: block;
  }

  .row-right {
    float: left;
    width: 100%;
    height: auto;
    margin: 0 0 10px 0;
    padding: 0;
    position: relative;
    display: block;
  }

  .row-right.show-details-button {
    width: 10%;
  }

  .text-center {
    text-align: center;
    line-height: 30px;
  }

  .arrow-first {
    float: left;
    width: 5%;
  }

  .arrow-prev {
    float: left;
    width: 5%;
  }

  .pager {
    float: left;
    width: 80%;
  }

  .pager a {
    padding: 0 5px;
  }

  .pager input[type="text"] {
    width: 30px;
    height: 26px;
  }

  .pager select {
    float: right;
  }

  .arrow-next {
    float: right;
    width: 5%;
  }

  .arrow-last {
    float: right;
    width: 5%;
  }

  /*inner page css <-*/

  /* Tablet Layout: 481px to 768px. Inherits styles from: Mobile Layout. */


  @media only screen and (min-width: 769px) {
    nav ul li:hover ul {
      display: block;
      position: absolute;
      background: #20b7c9;
      z-index: 2;
      width: 150px;
    }

 

    nav ul li:hover ul li {
      display: table;
      border-bottom: 1px solid #10a7b8;
      width: 100%;
    }

    nav ul li:hover ul li a {
      padding: 10px 10%;
      float: left;
      width: 80%;
    }

    nav ul li:hover ul li a:hover {
      background: #fff;
      color: #10a7b8;
    }

    nav ul li a:hover {
      background: #20b7c9;
    }

    nav ul li ul {
      display: none;
      margin: 37px 0 0 0;
    }

    nav ul li:hover ul {
      display: block;
      position: absolute;
      background: #20b7c9;
      z-index: 2;

    }
  }

  @media only screen and (min-width: 481px) {
    .gridContainer {
      width: 90.675%;
      padding-left: 1.1625%;
      padding-right: 1.1625%;
    }

    #LayoutDiv1 {
      clear: both;
      float: left;
      margin-left: 0;
      width: 100%;
      display: block;
    }

    .header {
      float: left;
      margin-top: 40px;
    }

    nav {
      float: left;
      width: 100%;
      height: auto;
      margin: 20px 0 10px 0;
      padding: 0;
      position: relative;
      display: block;
    }

    nav ul {
      float: left;
      width: auto;
      height: auto;
      margin: 0;
      padding: 0;
      position: relative;
      display: block;
    }

    nav ul li {
      list-style: none;
    }

    nav ul li a {
      text-decoration: none;
      float: left;
    }

    nav ul li a:hover,
    nav ul li a:focus {
      background: #20b7c9;
    }

    .active {
      background: #20b7c9;
    }

    nav ul li a:hover.selected {
      background: #20b7c9;
    }

    /* -> submenu*/
    nav ul li ul {
      display: none;
      margin: 37px 0 0 0;
    }



    /*submenu <-*/
    .container {
      float: left;
      width: 96%;
      height: auto;
      margin: 0;
      padding: 20px 2%;
      position: relative;
      display: block;
      // min-height: 450px;
      z-index: 1;
    }

    .login-div {
      float: left;
      width: 100%;
      max-width: 300px;
      height: auto;
      margin: 20px auto 0 auto;
      position: relative;
      display: block;
      /*border:1px solid #FFFFFF;*/
      box-shadow: 0px 0px 5px #999999;
      z-index: 2;
    }

    .login-row {
      height: 40px;
      padding: 0;
      position: relative;
      display: block;
      line-height: 40px;
    }

    .login-row h2 {
      margin: 0;
    }

    .login-label {
      width: 12%;
    }

    .login-fields {
      width: 72%;
    }

    .login-fields input[type="text"],
    input[type="password"] {
      width: 96%;
      height: 35px;
    }

    .bar-chart {
      right: 20px;
      bottom: 0px;
      width: 320px;
    }

    .copyright {
      width: 100%;
      padding: 0 2%;
    }

    /*-> inner page css*/
    .row {
      width: 100%;
    }

    .row-left {
      width: 33%;
    }

    .row-mid {
      width: 33%;
    }

    .row-right {
      width: 33%;
    }

    /*inner page css <-*/
  }

  /* Desktop Layout: 769px to a max of 1232px.  Inherits styles from: Mobile Layout and Tablet Layout. */

  @media only screen and (min-width: 769px) {
    .gridContainer {
      width: 78%;
      max-width: 1232px;
      padding-left: 0.9%;
      padding-right: 0.9%;
      margin: auto;
    }

    .container {
      min-height: 450px;
    }


    #LayoutDiv1 {
      clear: both;
      float: left;
      margin-left: 0;
      width: 100%;
      display: block;
    }

    .header {
      float: left;
    }

    nav {
      float: right;
      width: auto;
      height: auto;
      margin: 54px 10px 0;
      padding: 0;
      position: relative;
      display: block;
    }

    nav ul {
      margin: 0;
      padding: 0;
    }

    nav ul li {
      list-style: none;
      float: none;
      display: inline-block;
    }

    nav ul li a {
      text-decoration: none;
    }



    nav ul li a.active {
      background: #20b7c9;
    }

    /* -> submenu*/


    .new-opt {
      background: #0ea1b2;
      border-bottom: 1px solid #028897 !important;
    }

    .new-opt a:hover {
      background: #cefaff !important;
    }

    nav ul li:hover ul li {
      display: table;
      border-bottom: 1px solid #10a7b8;
    }

    nav ul li:hover ul li a:hover {
      background: #fff;
      color: #10a7b8;
    }

    /*submenu <-*/
    .container {
      float: left;
      width: 96%;
      height: auto;
      margin: 0;
      padding: 20px 2%;
      position: relative;
      display: block;
      min-height: 350px;
    }

    .login-div {
      float: left;
      width: 36%;
      height: auto;
      margin: 29px 0 0 12%;
      min-width: 400px;
      position: relative;
      display: block;
      /*border:1px solid #FFFFFF;*/
      box-shadow: 0px 0px 5px #999999;
      z-index: 2;
    }

    .login-row {
      float: left;
      height: 40px;
      padding: 0;
      position: relative;
      display: block;
      line-height: 40px;
    }

    .login-row h2 {
      margin: 0px;
    }

    .login-label {
      width: 12%;
    }

    .login-fields {
      width: 66%;
    }

    .login-fields input[type="text"],
    input[type="password"] {
      width: 96%;
      height: 35px;
    }

    .bar-chart {
      right: 20px;
      bottom: 20px;
      width: 340px;
    }

    .copyright {
      width: 96%;
      padding: 0 2%;
      min-width: max-content;
    }

    /*-> inner page css*/
    .row {
      width: 100%;
    }

    .row-left {
      width: 30%;
      margin: 0;
    }

    .row-mid {
      width: 30%;
      margin: 0;
    }

    .row-right {
      width: 30%;
      margin: 0;
    }

    /*inner page css <-*/
  }

  .captchaText {
    width: 59% !important;
  }

  .remainLoan {
    float: right;
    margin-top: -35px;
    color: red;
    padding-right: 10px;
  }

  .login_btn {
    background: #20b7c9;
    border: 1px solid #cccccc;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
    color: #fff;
    cursor: pointer;
    // padding: 2px;
  }

  .sd_passoword_toggle {
    border: none;
    width: 20px;
    margin-right: 15px;
    background: transparent !important;
    min-width: auto !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-top: 0.5rem;

    svg {
      cursor: pointer;
      color: #1d1f2b;
    }
  }

  .formloginBtn {
    padding: 5px;
    max-width: 60px;
    width: 100%;
    border-radius: 5px;
    border: transparent;
    border: 1px solid #cccccc;
    border-radius: 5px;
    background: #20b7c9;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    font-weight: 500;
    margin-bottom: 15px;
    min-height: 30px;
    margin-left: 15px;
    cursor: pointer;
    font-family: "Inter", sans-serif !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }

  .formloginBtnDisable {
    padding: 10px;
    max-width: 90px;
    width: 100%;
    border-radius: 5px;
    border: transparent;
    border: 1px solid #cccccc;
    border-radius: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    font-weight: 500;
    cursor: pointer;
    font-family: "Inter", sans-serif !important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  }

  @media(max-width: 480px) {
    // nav ul li ul {
    //   background: #505050;
    //   display: block;
    //   position: relative;
    //   z-index: 2;
    //   margin-top: 0;
    // }

    // margin-top: 0;
    //       background: #505050;
    // display: block;
    // position: relative;
    // z-index: 2;
    // margin-top: 0;

    //     nav ul li:hover ul {
    //       display: block;
    //        position: static;
    //       background: #535353;
    //   }

    //   nav ul li ul {
    //     position: absolute;
    //     left: 0;
    //     top: 0px;
    //     position: static;
    //     background: #505050;
    // }

    nav ul li ul {
      position: static;
      display: block;
      margin: 0;
    }

    // nav ul li:hover ul {
    //   background: unset;
    //   position: unset;
    // }

    // nav ul li:hover ul li {
    //   border: unset;
    // }

    // nav ul li ul::before,
    // nav ul li ul::after {
    //   content: "";
    //   background: #505050;
    //   display: block;
    //   position: absolute;
    //   z-index: 2;
    //   width: 10%;
    //   height: 100%;
    // }

    // nav ul li ul::before {
    //   left: -10%;
    // }

    // nav ul li ul::after {
    //   right: -10%;
    // }

    nav ul li ul li {
      border-bottom: 1px solid #00000000;
    }
  }

  .panel {
    display: none;
    float: left;
    position: absolute;
    right: 0;
    top: 40px;
    z-index: 3;
    background: rgba(0, 0, 0, .4);
    padding: 10px;
  }

  .panel a {
    float: right;
  }

  .date_today_cancel_buttons>button {
    margin: 0;
    background: unset;
    border: unset;
    padding: 0px 10px;
    width: 49%;
    min-height: 23px;
    border-radius: 5px;
    font-size: 13px;
    min-width: 49%;
  }

  .date_today_cancel_buttons>button:hover {
    background: #222;
  }

  .date_today_cancel_buttons {
    background: #666666;
    display: flex;
    justify-content: space-between !important;
    padding: 3px 2px !important;
    cursor: pointer;
  }

  .react-datepicker__day,
  .react-datepicker__week>div.react-datepicker__day--weekend {
    font-size: 13px;
    color: #000;
  }

  .react-datepicker__week>div.react-datepicker__day--weekend {
    background: #E8E8E8;
    color: #000;
  }

  .react-datepicker__week>div.react-datepicker__day--weekend:hover {
    background: #482424 !important;
    color: #fff !important;
  }

  .react-datepicker__week>div {
    border-radius: unset;
  }

  .end-date-picker {
    min-width: 186px;
    max-width: 186px;
  }

  @media only screen and (max-width: 768px) {
    nav ul li ul {
      display: block !important;
      position: static !important;
      background: unset !important;
      z-index: 2 !important;
      margin-top: 0 !important;
    }
    nav ul li a {
      padding: 10px;
  }
  .header{
    margin-left: -10px;
  }
  
  .revenue_recovery{
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

    .logOut_nav {
      position: relative;
      top: -5px;
    }



    nav ul li ul li a {
      // padding: 10px 15px;
      padding: 10px 10px;
    }

    // nav ul li:hover ul {
    //   display: block;
    //   position: static;
    //   background: #20b8c900;
    //   z-index: 2;
    //   width: 150px;
    // }

    // nav ul li:hover ul li {
    //   display: block;
    //   border-bottom: 1px solid #10a7b8;
    //   width: 100%;
    // }

    // nav ul li:hover ul li a {
    //   padding: 10px 10%;
    //   float: unset;
    //   width: 80%;
    // }

    // nav ul li:hover ul li a:hover {
    //   background: #fff;
    //   color: #10a7b8;
    // }
  }


  @media(max-width: 740px) {
    .logOut_bottom_btn {
      top: -5px;
      position: relative;
    }
  }


  @media(max-width:600px) {
    h2 {
      font-size: 21px;
    }

    .data-table table thead th span {
      font-weight: 700;
      font-size: 14px;
      letter-spacing: -0.5px;
    }

    .agent_details_table tr {
      display: flex;
      width: 100%;
      overflow: hidden;
    }

    .agent_details_table tr td{
      border: 1px solid #fff;
      padding: 5px;
      color: #666;
      word-break: break-all;
      font-size: 13px;
    }

    .agent_details_table thead tr th:first-child,
    .agent_details_table tbody tr td:first-child {
      width: 10%;
      min-width: 10%;
    }

    .agent_details_table thead tr th:nth-child(2),
    .agent_details_table tbody tr td:nth-child(2) {
      width: 20%;
      min-width: 20%;
      text-align: unset;

    }

    .agent_details_table thead tr th:nth-child(3),
    .agent_details_table tbody tr td:nth-child(3) {
      width: 45%;
      min-width: 45%;
      text-align: unset;

    }

    .agent_details_table thead tr th:nth-child(4),
    .agent_details_table tbody tr td:nth-child(4) {
      width: 15%;
      min-width: 15%;
    }
  }