.text-base {
  color: black;
  font-size: 14px;
}

.text-lg {
  color: black;
  font-size: 16px;
  text-align: center;
  margin: 2rem 0;
}

.today-date {
  color: red !important;
}
