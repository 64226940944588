.login-background {
	background: url("../../../public/body-bg.jpg");
}

.container {
	border-radius: 0 50px 0 0;
	display: flex;
	border: 1px solid #fff;
	border-bottom: none;
	background: rgba(255, 255, 255, 0.6);
	min-height: 350px;
	width: 70%;

	&:has(.game_revenue) {
		margin-top: 15px !important;
	}

	&:has(.game_revenue_pb_0) {
		padding-bottom: 0 !important;
	}
}

.login_div {
	position: relative;
	float: left;
	width: 36% !important;
	height: auto;
	margin: 5%;
	position: relative;
	display: block;
	box-shadow: 0px 0px 5px #999999;
}

.login_heading {
	background: #4fd3dd;
	padding: 6px 0;
	margin: 0;
	color: #fff;
	text-align: center;
	width: 100%;
	float: left;
	font-size: 21px Roboto;
}

.bar-chart {
	opacity: 0.5;
	position: absolute;
	right: 15rem;
	bottom: 8rem;

	img {
		max-width: 100%;
	}
}

body {
	background: url("../../../public/body-bg.jpg");
}

.main {
	.gridContainer {
		margin-left: auto;
		margin-right: auto;
		width: 87.36%;
		padding-left: 1.82%;
		padding-right: 1.82%;

		.logo {
			float: left;
			width: 300px;
			height: auto;
			margin: 20px 0 0 0;
			padding: 0;
			position: relative;
			display: block;

			@media (max-width: 576px) {
				margin-top: 40px;
			}
		}

		.header {
			nav {
				float: right;
				width: auto;
				height: auto;
				margin: 54px 10px 0;
				padding: 0;
				position: relative;
				display: block;

				@media(max-width: 808px) {
					margin-top: 10px;
				}

				ul li a {
					color: #fff;
					text-decoration: none;
					font-size: 14px;
					font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

					@media(min-width:769px) {
						&:hover {
							color: #20b7c9;
						}
					}
				}


				@media(min-width:769px) {
					.child-active {
						color: #20b7c9;
						background-color: #fff;
					}
				}

				.main_a {
					color: #fff;

					&:hover {
						color: #fff;
					}
				}
			}
		}

		// 	.container2 {
		// 	  float: left;
		// 	  width: 96%;
		// 	  height: auto;
		// 	  padding: 40px 3rem;
		// 	  position: relative;	
		// 	  display: block;
		// 	  min-height: 300px;
		// 	  z-index: 1;
		// 	  border-radius: 0 50px 0 0;
		// 	  border: 1px solid #fff;
		// 	  border-bottom: none;
		// 	  background: rgba(255, 255, 255, 0.6);
		//   }
	}

	.game_revenue {
		padding-top: 0 !important;
		padding-left: 0 !important;
		padding-right: 0 !important;
	}
}

.filter_details_tab_section .filter_inner_tab_info.gReportSec .custom_date_filter .date-label,
.filter_details_tab_section .filter_inner_tab_info.gReportSec .custom_date_filter .start-date-picker {
	margin-left: 0;
}

.manager_mail_details .start-date-picker, .manager_mail_details .end-date-picker,.start-date-picker, .filter_details_tab_section .filter_inner_tab_info.gReportSec .custom_date_filter .start-date-picker,.formloginBtn ,
.filter_details_tab_section .filter_inner_tab_info .custom_date_filter .end-date-picker,.filter_details_tab_section .filter_inner_tab_info .custom_date_filter .end-date-picker, .filter_details_tab_section .filter_inner_tab_info.gReportSec .custom_date_filter .date-label {
	margin-left: 0 !important;
	margin-right: 15px;
	color: #fff !important;
}

.filter_details_tab_section .filter_inner_tab_info .custom_date_filter{
	justify-content: unset !important;
}

.button_max {
	// max-width: max-content !important;
	min-width: max-content;
}

.dashboard_play .game_revenue.revenue_box > div > div.d_flex{
	justify-content: unset !important;
}

@media(max-width: 768px) {
	.game_revenue {
		padding-left: 10px !important;
		padding-right: 10px !important;
	}

	.game-rules-section {
		padding: 10px 0 !important;
	}

	.fun_ab_tables_section table tr {
		display: flex;
		width: 100%;
	}

	.fun_ab_tables_section table tr td:first-child,
	.fun_ab_tables_section table tr th:first-child {
		width: 10%;
		min-width: 10%;
	}

	.fun_ab_tables_section table tr td:nth-child(2),
	.fun_ab_tables_section table tr th:nth-child(2) {
		width: 20%;
		min-width: 20%;
	}
	.fun_ab_tables_section table tr td:nth-child(3),
	.fun_ab_tables_section table tr th:nth-child(3) {
		width: 62%;
		min-width: 62%;
	}
}

.common_table,
table {
	min-width: unset !important;
}

.login_input_line:focus {
	outline: 2px solid #000;
	border-radius: 3px;
}

.data-table {
	overflow-x: unset !important;
}

.common_table th,
.common_table td {
	padding-left: 5px;
	padding-right: 5px;
	text-align: center;
}


.start-date-picker {
	max-width: 179px;
	gap: 0 !important;
	border: unset;
	outline: unset;
}

@media(max-width: 768px) {
	nav>ul>li>ul>li>a:hover {
		background: unset;
	}
}

// @media(max-width: 620px){
// 	div:has(> .common_table){
//         overflow-x: auto !important;
// 	} 

// }
@media(max-width: 620px) {

	.publish_table.common_table td,
	.publish_table.common_table th {
		padding: 1%;
		width: 10%;
		min-width: 10%;
		border: 1px solid #fff;
		max-width: 10%;
		word-wrap: break-word;
	}

	.publish_table.common_table tbody tr {
		background: #E0F4F6;
	}

	.revenue_box {
		padding: 0 !important;
	}

	.publish_table.common_table th span svg {
		display: none;
	}

	.data-table:has(.publish_table.common_table) {
		max-height: 300px;
		overflow: auto;
	}

	.max_height_table {
		max-height: 80vh;
		overflow-y: auto;
	}

	.max_height_table .data-table:has(.publish_table.common_table) {
		max-height: unset;
		overflow: unset;
	}

	.title_daily_status {
		font-size: 21px;
		font-style: italic;
		margin-top: 10px;
		font-weight: 800;
		margin-bottom: 0 !important;
	}

	.sec_daily_status {
		padding-left: 0 !important;
		padding-right: 0 !important;
	}

	.sec_daily_status .date-label {
		margin-left: 0 !important;
		margin-right: 10px !important;
		margin-bottom: 4px !important;
		font-size: 14px !important;
	}

	.sec_daily_status .filter_inner_tab_info.gReportSec {
		justify-content: start !important;
	}

	.sec_daily_status .formloginBtn {
		margin-left: 0 !important;
		font-weight: 700;
	}

	.sec_daily_status .start-date-picker {
		margin-right: 15px !important;
		margin-left: 0 !important;
		max-width: 153px !important;
		margin-bottom: 4px !important;
		border: 1px solid #9b9b9b;
	}

	.sec_daily_status .start-date-picker input::placeholder {
		display: none !important;
		font-size: 0 !important;

	}

	.sec_daily_status .custom_date_filter {
		justify-content: start !important;
	}

}

@media(max-width: 430px) {

	.publish_table.common_table td,
	.publish_table.common_table th {
		word-break: break-all;
	}
}