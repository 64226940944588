.pagination-container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 8px;
  padding: 10px;
  border: 1px solid #fff !important;
  // border: 1px solid #ddd;
  // border-radius: 8px;

  .pagination-btn {
    padding: 5px 10px;
    border: none;
    background: #20b7c9;
    color: #fff;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;
    color: #000;
    font-weight: 900;
    padding: 0px 10px;
    .rotate_4g{
        transform: rotate(180deg);
    }
    .pagination_img{
      position: relative;
      top: 2px;
      left: 1px;
      height: 23px;
    }
    img{
      height: 19.2px;
      min-height: 19.2px;
      min-width: 20px;
      top: 1px;
      left: 1px;
      object-fit: contain;
    }

    &:hover {
      background: #20b7c9;
    }

    &:disabled {
      background: #e0e0e0;
      background: #20b7c9;
      cursor: not-allowed;
      // opacity: 0.6;
      font-weight: 900;
  
    }
@media(max-width: 600px){
  padding-left: 0;
  padding-right: 0;
}
  }

  .pagination-btns{
    padding: 5px 10px;
    border: none;
    background: #f1f3f3;
    color: #000000;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: #20b7c9;
    }

    &:disabled {
      background: #e0e0e0;
      cursor: not-allowed;
      opacity: 0.6;
    }

    &.active {
      // background-color: #e0e0e0;
      background-color: unset;
      color: black;
      font-weight: bold;
    }
  }
  .pagination-page {
    padding: 5px 10px;
    border: 1px solid #ddd;
    border-radius: 4px;
    cursor: pointer;
    background: #fff;

    &.active {
      background: #20b7c9;
      color: #fff;
      border-color: #20b7c9;
    }
  }

  .pagination-input {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 20px;

    input {
      width: 50px;
      padding: 5px;
      border: 1px solid #ddd;
      border-radius: 4px;
      text-align: center;
      margin-right: 5px;
    }
  }

  .pagination-select {
    padding: 0;
    border: 1px solid #ddd;
    border-radius: 4px;
    background: #fff;
  }
}

.pagination-info {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 100px;
  flex-wrap: wrap;
  .pagination_number{
    display: flex;
    align-items: baseline;
    .pagination-input{
      margin-right: 3px;
    }
  }

  .pagination-go-btn, .pagination-container .pagination-btn:disabled {
    background: #20b7c9;
    border: none;
    border-radius: 5px;
    color: #fff;
    font-weight: 500;
    min-width: 30px;
    max-height: 20px;
  }
}