@charset "utf-8";
/* Simple fluid media
   Note: Fluid media requires that you remove the media's height and width attributes from the HTML
   http://www.alistapart.com/articles/fluid-images/ 
*/
img,
object,
embed,
video {
  max-width: 100%;
}
/* IE 6 does not support max-width so default to width 100% */
.ie6 img {
  width: 100%;
}

/*
	Dreamweaver Fluid Grid Properties
	----------------------------------
	dw-num-cols-mobile:		5;
	dw-num-cols-tablet:		8;
	dw-num-cols-desktop:	10;
	dw-gutter-percentage:	25;
	
	Inspiration from "Responsive Web Design" by Ethan Marcotte 
	http://www.alistapart.com/articles/responsive-web-design
	
	and Golden Grid System by Joni Korpi
	http://goldengridsystem.com/
*/

/* Mobile Layout: 480px and below. */
.top-bar {
  float: left;
  width: 100%;
  height: 2px;
  background: #737373;
  /*border-top:5px solid #000000;*/
  border-bottom: 2px solid #249fab;
  position: fixed;
  z-index: 3;
}
.gridContainer {
  margin-left: auto;
  margin-right: auto;
  width: 87.36%;
  padding-left: 1.82%;
  padding-right: 1.82%;
}
#LayoutDiv1 {
  clear: both;
  float: left;
  margin-left: 0;
  width: 100%;
  display: block;
}
.header {
  float: left;
  width: 100%;
  height: auto;
  margin: 20px 0 10px 0;
  padding: 0;
  position: relative;
  display: block;
}
.login-button {
  float: right;
  width: auto;
  height: auto;
  margin: 34px 50px 0 0;
  padding: 0;
  position: relative;
  display: block;
  font-size: 36px;
}
.logo {
  float: left;
  width: 300px;
  height: auto;
  margin: 20px 0 0 0;
  padding: 0;
  position: relative;
  display: block;
}
.panel {
  display: none;
  float: left;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 3;
  background: rgba(0, 0, 0, 0.4);
  padding: 10px;
}
.panel a {
  float: right;
}
.user-details {
  position: absolute;
  right: 0;
  text-align: right;
  margin: 0 0 10px 0;
  background: #fff;
  padding: 0 10px;
  color: #20b7c9;
  border-radius: 5px;
  border: 2px solid #d1d1d1;
  box-shadow: 0px 0px 14px #fff;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.user-details i {
  margin: 10px 0 0 0;
}
.responsive_menu select {
  display: block;
  width: 100%;
  height: 36px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857;
  color: #ffffff;
  vertical-align: middle;
  background-color: #20b7c9;
  background-image: none;
  border: none;
}
nav {
  float: left;
  width: 100%;
  height: auto;
  margin: 20px 0 0 0;
  padding: 0;
  position: relative;
  display: block;
}
nav ul {
  float: left;
  width: auto;
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
}
nav ul li {
  margin: 0;
  display: inline-block;
  list-style: none;
  float: left;
}
nav ul li a {
  text-decoration: none;
  padding: 10px 18px;
  float: left;
}
nav ul li a:hover {
  background: #20b7c9;
}
.active {
  background: #20b7c9;
}
.container {
  float: left;
  width: 96%;
  height: auto;
  margin: 30px 0 0 0;
  padding: 20px 2%;
  position: relative;
  display: block;
  min-height: 300px;
  z-index: 1;
  border-radius: 0 50px 0 0;
  border: 1px solid #fff;
  border-bottom: none;
  background: rgba(255, 255, 255, 0.6);
}
.login-div {
  float: left;
  width: 96%;
  height: auto;
  margin: 50px 2% 0 2%;
  padding: 0 0 10px 0;
  position: relative;
  display: block;
  border: 1px solid #ffffff;
  box-shadow: 0px 0px 5px #999999;
  z-index: 2;
  background: rgba(0, 0, 0, 0.3);
}
.fields {
  float: left;
  width: 92%;
  margin: 10px 4%;
  padding: 0;
  position: relative;
  display: block;
  background: rgb(226, 226, 226); /* Old browsers */
  /* IE9 SVG, needs conditional override of 'filter' to 'none' */
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2UyZTJlMiIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNmZmZmZmYiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  background: -moz-linear-gradient(
    top,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(226, 226, 226, 1)),
    color-stop(100%, rgba(255, 255, 255, 1))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(
    top,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(
    top,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* Opera 11.10+ */
  background: -ms-linear-gradient(
    top,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* IE10+ */
  background: linear-gradient(
    to bottom,
    rgba(226, 226, 226, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  ); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e2e2e2', endColorstr='#ffffff',GradientType=0 ); /* IE6-8 */
}
.login-row-width {
  width: 100%;
}
.login-submit {
  width: 92%;
  margin: 0 4%;
}
.login-row {
  float: left;
  height: 40px;
  padding: 0;
  position: relative;
  display: block;
  line-height: 40px;
}
.login-row h2 {
  margin: 0;
  background: #4fd3dd;
  padding: 0;
  color: #fff;
  text-align: center;
  width: 100%;
  float: left;
}
.login-label {
  float: left;
  width: 12%;
  height: auto;
  margin: 2px 0 0 5%;
  padding: 0;
  position: relative;
  display: block;
}
.login-fields {
  float: left;
  width: 68%;
  height: auto;
  // margin: 0 2% 0 0;
  padding: 0;
//   position: calc();
  display: block;
}
.login-row span {
  float: right;
  position: absolute;
  top: 5px;
  right: 7px;
  margin-top: -6px;
}

.imgLeftMargin {
  margin-left: -10px;
}

.login-fields input[type="submit"] {
  float: right;
}
.login-fields input[type="text"],
input[type="password"] {
  width: 180px;
  height: 35px;
  border-radius: none;
  border: none;
}
.bar-chart {
  position: absolute;
  display: block;
  right: 20px;
  bottom: 20px;
  width: 120px;
  z-index: 1;
  opacity: 0.2;
}
footer {
  float: left;
  width: 100%;
  height: auto;
  margin: 0;
  padding: 0;
  position: relative;
  display: block;
  line-height: 40px;
  color: #fff;
  background: bottom repeat-x #16b2c3;
  border-radius: 0 0 20px 20px;
  margin: 0 0 20px 0;
  border: 1px solid #ffffff;
  border-top: none;
}
.copyright {
  float: left;
  width: 46%;
  height: auto;
  margin: 0;
  padding: 0 2%;
  position: relative;
  display: block;
}
/*-> inner page css*/
.row {
  width: 100%;
  float: left;
  width: 100%;
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
  display: block;
  z-index: 2;
}
.row-left {
  float: left;
  width: 100%;
  height: auto;
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
  display: block;
}
.row-mid {
  float: left;
  width: 100%;
  height: auto;
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
  display: block;
}
.row-right {
  float: left;
  width: 100%;
  height: auto;
  margin: 0 0 10px 0;
  padding: 0;
  position: relative;
  display: block;
}
.row-right.show-details-button {
  width: 10%;
}
.text-center {
  text-align: center;
  line-height: 30px;
}
.arrow-first {
  float: left;
  width: 5%;
}
.arrow-prev {
  float: left;
  width: 5%;
}
.pager {
  float: left;
  width: 80%;
}
.pager a {
  padding: 0 5px;
}
.pager input[type="text"] {
  width: 30px;
  height: 26px;
}
.pager select {
  float: right;
}
.arrow-next {
  float: right;
  width: 5%;
}
.arrow-last {
  float: right;
  width: 5%;
}
/*inner page css <-*/

/* Tablet Layout: 481px to 768px. Inherits styles from: Mobile Layout. */

@media only screen and (min-width: 481px) {
  .gridContainer {
    width: 90.675%;
    padding-left: 1.1625%;
    padding-right: 1.1625%;
  }
  #LayoutDiv1 {
    clear: both;
    float: left;
    margin-left: 0;
    width: 100%;
    display: block;
  }
  .header {
    float: left;
  }
  nav {
    float: left;
    width: 100%;
    height: auto;
    margin: 20px 0 10px 0;
    padding: 0;
    position: relative;
    display: block;
  }
  nav ul {
    float: left;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  nav ul li {
    list-style: none;
  }
  nav ul li a {
    text-decoration: none;
    float: left;
  }
  nav ul li a:hover,
  nav ul li a:focus {
    background: #20b7c9;
  }
  .active {
    background: #20b7c9;
  }
  nav ul li a:hover.selected {
    background: #20b7c9;
  }

  /* -> submenu*/
  nav ul li ul {
    display: none;
    margin: 37px 0 0 0;
  }
  nav ul li:hover ul {
    display: block;
    position: absolute;
    background: #20b7c9;
    z-index: 2;
    width: 150px;
  }
  nav ul li:hover ul li {
    display: table;
    border-bottom: 1px solid #10a7b8;
    width: 100%;
  }
  nav ul li:hover ul li a {
    padding: 10px 10%;
    float: left;
    width: 80%;
  }
  nav ul li:hover ul li a:hover {
    background: #fff;
    color: #10a7b8;
  }
  /*submenu <-*/
  .container {
    float: left;
    width: 96%;
    height: auto;
    margin: 0;
    padding: 20px 2%;
    position: relative;
    display: block;
    min-height: 450px;
    z-index: 1;
  }
  .login-div {
    float: left;
    width: 46%;
    height: auto;
    margin: 50px 25% 0 25%;
    position: relative;
    display: block;
    /*border:1px solid #FFFFFF;*/
    box-shadow: 0px 0px 5px #999999;
    z-index: 2;
  }
  .login-row {
    float: left;
    height: 40px;
    padding: 0;
    position: relative;
    display: block;
    line-height: 40px;
  }
  .login-row h2 {
    margin: 0;
  }
  .login-label {
    width: 12%;
  }
  .login-fields {
    width: 72%;
  }
  .login-fields input[type="text"],
  input[type="password"] {
    width: 96%;
    height: 35px;
  }
  .bar-chart {
    right: 20px;
    bottom: 20px;
    width: 370px;
  }
  .copyright {
    width: 46%;
    padding: 0 2%;
  }
  /*-> inner page css*/
  .row {
    width: 100%;
  }
  .row-left {
    width: 33%;
  }
  .row-mid {
    width: 33%;
  }
  .row-right {
    width: 33%;
  }
  /*inner page css <-*/
}

/* Desktop Layout: 769px to a max of 1232px.  Inherits styles from: Mobile Layout and Tablet Layout. */

  .gridContainer {
    width: 78%;
    max-width: 1232px;
    padding-left: 0.9%;
    padding-right: 0.9%;
    margin: auto;
  }
  #LayoutDiv1 {
    clear: both;
    float: left;
    margin-left: 0;
    width: 100%;
    display: block;
  }
  .header {
    float: left;
  }
  nav {
    float: right;
    width: 100%;
    height: auto;
    // margin: 54px 10px 0;
    padding: 0;
    position: relative;
    display: block;
  }
  nav ul {
    margin: 0;
    padding: 0;
  }
  nav ul li {
    list-style: none;
    float: none;
    display: inline-block;
  }
  nav ul li a {
    text-decoration: none;
  }
  nav ul li a:hover {
    background: #20b7c9;
  }
  nav ul li a.active {
    background: #20b7c9;
  }
  /* -> submenu*/
  nav ul li ul {
    display: none;
    margin: 37px 0 0 0;
  }
  nav ul li:hover ul {
    display: block;
    position: absolute;
    background: #20b7c9;
    z-index: 2;
  }
  .new-opt {
    background: #0ea1b2;
    border-bottom: 1px solid #028897 !important;
  }
  .new-opt a:hover {
    background: #cefaff !important;
  }

  @media(min-width: 769px){
    nav ul li:hover ul li {
      display: table;
      border-bottom: 1px solid #10a7b8;
    }
    nav ul li:hover ul li a:hover {
      background: #fff;
      color: #10a7b8;
    }


  }

  /*submenu <-*/
  .container {
    float: left;
    width: 96%;
    height: auto;
    margin: 0;
    padding: 20px 2%;
    position: relative;
    display: block;
    min-height: 350px;
  }
  .login-div {
    float: left;
    width: 36%;
    height: auto;
    margin: 29px 0 0 12%;
    position: relative;
    display: block;
    /*border:1px solid #FFFFFF;*/
    box-shadow: 0px 0px 5px #999999;
    z-index: 2;
  }
  .login-row {
    float: left;
    height: 40px;
    padding: 0;
    position: relative;
    display: block;
    line-height: 40px;
  }
  .login-row h2 {
    margin: 0;
  }
  .login-label {
    width: 12%;
  }
  .login-fields {
    width: 66%;
  }
  .login-fields input[type="text"],
  input[type="password"] {
    width: 96%;
    height: 35px;
  }
  .bar-chart {
    right: 20px;
    bottom: 20px;
    width: 340px;
  }
  .copyright {
    width: 96%;
    padding: 0 2%;
  }

  /*-> inner page css*/
  .row {
    width: 100%;
  }
  .row-left {
    width: 30%;
    margin: 0;
  }
  .row-mid {
    width: 30%;
    margin: 0;
  }
  .row-right {
    width: 30%;
    margin: 0;
  }
  /*inner page css <-*/

.captchaText {
  width: 59% !important;
}

.remainLoan {
  float: right;
  margin-top: -35px;
  color: red;
  padding-right: 10px;
}
