body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.logOut_section {
  // display: none;
  float: left;
  position: absolute;
  right: 0;
  top: 40px;
  z-index: 3;
  background: rgba(0, 0, 0, .4);
  padding-left: 10px;
  padding-right: 10px;
  height: 0;
  transition: 0.4s ease;
  overflow: hidden;
}

.logout_show {
  // display: unset;
  padding-top: 10px;
  padding-bottom: 10px;
  height: max-content;
}

.logOut_section a {
  float: right;
  color: #FFFFFF;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #20B7C9;
  padding: 5px 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.logOut_section a i {
  display: inline-block;
  font: normal normal normal 14px / 1 FontAwesome;
  // font-size: inherit;
  // text-rendering: auto;
  font-size: 14px // border: 1px solid #cccccc;
}

.logOut_section a span {
  font-size: 13px;
  padding: 0;
  position: relative;
  top: -1px;
  
}

.react-datepicker__month-container,
.react-datepicker__header {
  background: #666666
}

.react-datepicker__current-month {
  color: #fff
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--scroll {
  color: #fff;
}

.react-datepicker__day-names>div {
  background: #20B7C9;
  margin: 0;
  border: 1px solid #b1b1b1;
  padding-left: 2.5px;
  padding-right: 2.5px;
  font-weight: 700;
  font-size: 13px;
}

.react-datepicker__week>div {
  border: 1px solid #CDCDCD;
  padding-left: 2.5px;
  padding-right: 2.5px;
  margin: 0
}

.react-datepicker__month {
  margin: 0
}

.react-datepicker__week>div {}

.react-datepicker__navigation-icon--previous::before,
.react-datepicker__navigation-icon--next::before {
  border-color: #fff;
  top: 6px;
  width: 9px;
}

h2.react-datepicker__current-month {
  padding: 0 0 7px 0;
}

.react-datepicker__week>div.react-datepicker__day--outside-month,
.react-datepicker__day--weekend.react-datepicker__day--outside-month {
  background: #20B7C9 !important;
  color: #fff !important;
}

.react-datepicker__week>div.react-datepicker__day--weekend {
  background: #D8D8D8;
  border: 1px solid #7BACD2;
  color: #000;
  border-radius: unset;
}

.react-datepicker__day-names {
  display: flex;
}

.Approved-reject-section>div.MuiBox-root {
  width: 80%;
}


.start-date-picker {
  max-width: 179px;
  gap: 0 !important;
  border: unset;
  outline: unset;
}

.react-datepicker__header.react-datepicker__header--custom>div>span:nth-child(2) {
  font-size: 13px !important;
  position: relative;
  top: -3.5px;
  color: #fff;
  font-weight: 700;
}

.react-datepicker__header.react-datepicker__header--custom>div>button {
  font-size: 13px !important;
  position: relative;
  top: -6px;
  background: unset;
  border: unset;
  color: #fff;
  padding: 0 30px;
  cursor: pointer;
}

.react-datepicker__week > div:hover{
  background: #482424 !important;
  color: #fff;
  cursor: pointer;
  border-radius: 0 !important;
}

.react-datepicker__header.react-datepicker__header--custom > div:first-child{
  display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      top: 0px;
      padding: 5px;
}


// .react-datepicker__day-names > div[aria-label="Monday"]{
//   order: 1
// }
// .react-datepicker__day-names > div[aria-label="Tuesday"]{
//   order: 2
// }
// .react-datepicker__day-names > div[aria-label="Wednesday"]{
//   order: 3
// }
// .react-datepicker__day-names > div[aria-label="Thursday"]{
//   order: 4
// }
// .react-datepicker__day-names > div[aria-label="Friday"]{
//   order: 5
// }
// .react-datepicker__day-names > div[aria-label="Saturday"]{
//   order: 6
// }
// .react-datepicker__day-names > div[aria-label="Sunday"]{
//   order: 7
// }

.react-datepicker__week {
  display: flex
}

.react-datepicker__month-container {
  position: relative;
}

.react-datepicker__day {
  border: 1px solid #7BACD2;
  background: #F3F3F3;
  color: #CDCDCD;
}

// .react-datepicker__header--custom > div:first-child{
//   margin: 10px;
//   display: flex ;
//   justify-content: center;
//   position: absolute;
//   bottom: -179px;
//   left: 0;
//   background: #666666;
//   width: 100%;
//   margin-left: 0 !important;
//   padding: 3px;
//   }

// .react-datepicker__header--custom > div:first-child > button{
//   background: unset;
//   width: 50%;
//   border: none;
//   cursor: pointer;
//   transition: 0.4s ease;
//   color: #fff;
// }

// .react-datepicker__header--custom > div:first-child > button:hover{
//   background: #222;
//   border-radius: 5px;
// }




// .react-datepicker__week > div:first-child{
//   order: 7
// }
// .react-datepicker__week > div:nth-child(1){
//   order: 1
// }
// .react-datepicker__week > div:nth-child(2){
//   order: 2
// }
// .react-datepicker__week > div:nth-child(3){
//   order: 3
// }
// .react-datepicker__week > div:nth-child(4){
//   order: 4
// }
// .react-datepicker__week > div:nth-child(5){
//   order: 5
// }
// .react-datepicker__week > div:nth-child(6){
//   order: 6
// }

@import "./assets/Style/app.scss";